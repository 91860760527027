@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Container untuk video */
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Pastikan video tetap di belakang elemen lainnya */
}

/* Video background */
.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Pastikan video mengisi layar */
}

/* Overlay gelap */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.267); /* Warna hitam dengan opasitas 50% */
  z-index: 1; /* Letakkan di atas video */
}

/* Teks di tengah */
.centered-text {
  position: absolute;
  top: 45%; /* Posisikan di tengah secara vertikal */
  left: 50%; /* Posisikan di tengah secara horizontal */
  transform: translate(-50%, -50%); /* Geser untuk benar-benar berada di tengah */
  color: white; /* Warna teks */
  font-size: 2rem; /* Ukuran teks lebih besar */
  font-weight: 600; /* Gunakan font-weight tebal */
  text-align: center; /* Pusatkan teks */
  font-family: 'Poppins', sans-serif; /* Font yang diimpor */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.363); /* Tambahkan bayangan teks */
  z-index: 2; /* Letakkan di atas overlay */
  transition: opacity 0.3s ease; /* Animasi halus saat hover */
}

/* Efek saat hover */
.centered-text:hover {
  opacity: 0.4; /* Jadikan teks semi-transparan */
}
